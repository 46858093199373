.time-range-select {
  background: #fff;
  font-size: 1.5rem;
  > div {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
  fieldset {
    outline: none;
  }
}
