@import "../../../assets/styles/variables";

.login-form {
  background: rgb(26, 32, 56);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  > form {
    width: 50vw;
    display: flex;
    > div {
      width: 50%;
    }
    .branding {
      background: rgb(23,29,55);
      background: radial-gradient(circle, rgba(23,29,55,1) 0%, rgba(26,32,56,1) 70%, rgba(34,42,76,1) 100%);
      border-radius: 10px 0 0 10px;
      color: #fff;
      padding-left: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        font-size: 1.5rem;
        margin-bottom: 20px;
        span {
          font-weight: bold;
        }
      }
      h2 {
        font-size: 1rem;
      }
    }
    .form {
      border-radius: 0 10px 10px 0;
      background: #fff;
      padding: 5rem 30px;
      position: relative;
      > div {
        &.checkbox {
          input {
            width: auto;
            margin-right: 0.5rem;
          }
        }
      }
    }
    img {
      display: block;
      width: 150px;
      margin: 10px auto 20px auto;
    }
    em {
      font-size: 0.8rem;
      color: #999;
      display: block;
    }
    > div {
      &.login-error {
        padding: 0;
        margin-top: 1rem;
        color: red;
      }
      &.reset-success {
      }
    }
    .links {
      margin-top: 20px;
      font-size: 0.9rem;
    }
    .error {
      font-size: 0.85rem;
      position: absolute;
      padding-right: 0.5rem;
      bottom: 1rem;
      color: map-get($map: $theme-colors, $key: secondary);
      display: flex;
      align-items: center;
      svg {
        font-size: 16px;
        margin-right: 5px;
      }
    }
    .m-top {
      margin-top: 10px;
    }
    input {
      border: 1px solid #ccc;
      padding: 5px 10px;
      width: 100%;
      border-radius: 5px;
      margin-bottom: 0.5rem;
    }
    button {
      display: flex;
      margin-top: 1rem;
      span {
        display: flex;
        div {
          margin-left: 10px;
          svg {
            width: 18px;
            color: #888;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .login-form {
    > form {
      width: 100vw;
      padding: 0;
      box-shadow: none;
    }
  }
}
