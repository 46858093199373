@import "../variables";
@import "../mixins";

.save-changes-bar {
  visibility:hidden;
  height:50px;
  position: fixed;
  z-index:10;
  bottom:-50px;
  left: $cr-sidebar-width;
  right: 0;
  border-top:1px solid #ccc;
  overflow: hidden;
  background: $pearl;
  @include flexbox;
  @include align-items(center);
  @include transition(bottom 0.5s ease-out, visibility 0.5s ease-out);
  > div:first-child {
    @include flex(1);
    padding:0 10px;
  }
  .footer-message {
    color: $primary;
    opacity:0.01;
    @include transform(translateX(-5px));
    @include transition(transform 0.5s ease, opacity ease);
    @include transition-delay(0.6s);
  }
  button {
    margin-right:12px;
    i {
      float:left;
      margin:0 0 5px -10px;
    }
    &.btn-primary-success {
      background-color:$leaf;
      border-color:$leaf;
      &:hover,
      &:active,
      &:focus {
        background-color:$leaf;
        border-color:$leaf;
      }
    }
  }
  &.disable-footer {
    pointer-events: none;
  }
  &.show-footer {
    bottom:0;
    visibility:visible;
    .footer-message {
      opacity:1;
      @include transform(translateX(10px));
    }
  }
}
