.settings-page {
  padding-top: 40px;
  font-size: 0.9rem;
  display: flex;

  .source-id-cnt {
    margin-bottom: 30px;
    line-height: 20px;

    .source-id {
      margin-left: 20px;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }

  .global-settings {
    margin-bottom: 50px;

    .minTimeBetweenLogs {
      width: 200px;
    }
  }

  .widget-settings {

    .widget-script-row {
      margin-top: 50px;
      display: flex;
      flex-direction: column;

      .widget-script {
        background-color: #e5eae9;
        border-radius: 4px;
        //width: 600px;
        height: 100px;
        overflow: auto;
        border: 1px #999 solid;
        overflow-wrap: anywhere;
      }
    }

    .widget-domains-cnt {
      margin-top: 20px;

      textarea.form-control {
        height: 100px;
      }
    }

  }

  input[type=checkbox] {
    margin-right: 10px;
  }
}

