@import "../variables";

.incident-flows-page {
  padding: 20px 0 20px 0;
  .g-header,
  .grid-table-row {
    grid-template-columns: 50px 50px 50px 65px 80px 80px minmax(250px, auto) 1fr minmax(200px, auto);
  }

  .row-error {
    border: 1px solid red;
  }
}

.add-incident-flow-form {
  > div {
    margin: 20px 0;
  }
  em {
    display: block;
    color: $gray;
    font-size: 0.8rem;
  }
  input {
    width: 100%;
    padding: 5px;
    border: 1px solid $silver;
    border-radius: 3px;
  }
}
