@import "../variables";

.integrations-page {
  padding: 2rem;
  .integrations-container {
    display: flex;
    flex-direction: row;
    > .integration {
      display: flex;
      flex-flow: column;
      margin-left: 30px;
      &:first-child {
        margin-left: 0;
      }
      > .revoke {
        text-align: center;
        padding-top: 10px;
        button {
          vertical-align: middle;
          font-size: 12px;
          padding: 5px 10px;
          border: none;
          opacity: 0.8;
          box-shadow: 0 0 5px #eee;
          &:hover {
            box-shadow: 0 0 5px #ccc;
            opacity: 1;
          }
          span {
            vertical-align: middle;
            padding-left: 5px;
          }
        }
      }
      > div:first-child {
        padding: 20px;
        cursor: pointer;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        position: relative;
        &:hover {
          box-shadow: 0 0 10px rgba(0,0,0,0.4);
          .actions {
            opacity: 0.7;
          }
        }
        > button {
          width: 180px;
          height: 180px;
          border: none;
          &:focus,
          &:active {
            outline: none;
          }
          &.Slack {
            background: url("../../../assets/img/integrations/slack-logo.png") no-repeat center center;
            background-size: contain;
          }
          &.Jira {
            background: url("../../../assets/img/integrations/jira-logo.png") no-repeat center center;
            background-size: contain;
          }
        }
        .actions {
          position: absolute;
          top: 10px;
          left: 10px;
          right: 10px;
          opacity: 0;
        }

        .status {
          position: absolute;
          text-align: center;
          vertical-align: middle;
          left: 0;
          right: 0;
          bottom: 10px;
          em {
            vertical-align: middle;
          }
          &_valid {
            color: green;
          }
          &_invalid {
            color: darkred;
          }
        }
      }
    }
  }




}

.slack-integration-form {
  > div {
    margin: 20px 0;
  }
  em {
    display: block;
    color: $gray;
    font-size: 0.8rem;
  }
  input {
    width: 100%;
    padding: 5px;
    border: 1px solid $silver;
    border-radius: 3px;
  }
}

.jira-integration-form {
  padding: 50px;
  text-align: center;
  button {
    padding: 10px 20px;
    border: none;
    box-shadow: 0 0 10px #ccc;
    border-radius: 10px;
    &:hover {
      box-shadow: 0 0 10px $black;
    }
    span {
      vertical-align: middle;
      padding-left: 5px;
    }
  }
}
