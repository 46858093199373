.dialog {
  .dialog-content {
    padding: 20px;
  }

  .dialog-footer {
    padding: 20px;
    button {
      margin-left: 20px;
    }
  }
}

