
.flows {
  padding: 20px;

  .active-rules {
    margin-bottom: 20px;

    .active-rule {
      display: flex;

      .arrow {
        padding: 0;

        &.hidden {
          visibility: hidden;
        }
      }
    }
  }

  .idle-rules {

  }
}
