@import "../variables";

.flow-editor {
  padding: 20px;
  width: 80%;
  margin: auto;

  .col {
    margin-bottom: 1rem;
    &.align-bottom {
      align-self: flex-end;
    }
  }

  input[type=checkbox] {
    margin: 0 10px;
  }

  input[type=number] {
    width: 80px;
  }

  .name {
    .label-cnt {
      width: 85px;
    }
  }

  .error {
    color: red;
  }

  .drawer-cnt {


    .criteria-drawer {
      background-color: #f4f4f4;
      padding: 1rem;
      width: 100%;

      .drag-handle {
        margin: 0 1rem;
        cursor: move;
        align-self: center;
      }

      > div {
        padding: 0.5rem 0;
      }

      .actions-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .actions-header {
          display: flex;
        }
        .integration-error {
          color: red;
        }
        &:first-child {
          padding: 0;
          justify-content: space-between;
          strong {
            margin-left: 1rem;
          }
          div:last-child {
            padding-right: 5rem;
          }
        }
        &:nth-child(3) {
          border-bottom: 1px solid #ddd;
          padding-bottom: 1rem;
          padding-left: 5px;
          padding-top: 0.5rem;
          justify-content: space-between;
          align-items: center;
          button {
            margin-right: 5rem;
            width: auto;
            span {
              vertical-align: middle;
              margin-right: 5px;
            }
          }
          label {
            margin: 0;
          }
        }
        > div, strong {
          padding: 0.5rem 0;
          align-self: center;
        }
        .actions-container {
          padding-left: 5rem;
          margin: 0;
          textarea {
            height: 250px;
          }
          > div {
            width: 100%;
            padding-right: 5rem;
            margin-right: 0;
          }
          em {
            margin-top: 1rem;
            display: inline-block;
            color: #666;
            font-size: 0.8rem;
            &:first-child {
              margin-top: 0;
            }
          }
        }
        strong {
          cursor: pointer;
          &:hover {
            color: red;
          }
        }
      }

      .criteria-group {
        > div, strong {
          padding: 0.5rem 0;
          margin-right: 1rem;
          align-self: center;
        }
        strong {
          cursor: pointer;
          &:hover {
            color: red;
          }
        }
      }

      .criteria-buttons {
        > div {
          margin-right: 1rem;
          padding: 0.5rem 0;
        }
      }

      .dragged {
        opacity: 0.1;
        background: #eee;
      }

      .simple-criteria {

        > div {
          margin-right: 1rem;
          padding: 0.5rem 0;
        }
        strong {
          align-self: center;
          cursor: pointer;
          &:hover {
            color: red;
          }
        }
      }

      .level-0 {
        margin-left: 0px;
      }

      .level-1 {
        margin-left: 30px;
      }

      .level-2 {
        margin-left: 60px;
      }

      .level-3 {
        margin-left: 90px;
      }

      .level-4 {
        margin-left: 120px;
      }

      .level-5 {
        margin-left: 150px;
      }

      .level-6 {
        margin-left: 180px;
      }
    }
  }
}

.modal-dialog.modal-action-test {
  max-width: 900px;
  max-height: 700px;

  .modal-content {
    width: 900px;
    height: 600px;
  }

  .action-result {
    width: 100%;
    height: 185px;
    overflow: auto;
    background: #f6f6f6;
  }

  textarea {
    height: 200px;
    max-height: 200px;
  }
}
