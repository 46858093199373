@import "../variables";
@import "../mixins";

@keyframes highlight {
  0% {
    height:0;
    overflow:hidden;
    background: #ffc;
  }
  100% {
    height:50px;
    opacity:1;
    background: transparent;
  }
}
.s-row--highlight {
  animation: highlight 0.5s;
}

.fl-grid {
  &__actions {
    padding: 0 25px;
    margin-bottom: 10px;
    @include transition(padding 1s);
    @include flexbox;
    @include align-items(center);
    > div:first-child {
      @include flex-grow(1);
    }
    @media (max-width: $m-md) {
      flex-direction: column;
      margin: 0;
      > div {
        width: 100%;
        margin: 0.5rem 0;
      }
      > div:first-child {
        display: flex;
      }
      form {
        width: 100%;
      }
    }
    .input-group {
      width: 20vw;
      box-shadow: 0 0 2px #ccc;
      border-radius: 5px;
      @media (max-width: $m-md) {
        width: 100%;
      }
      input {
        padding-right: 10px;
        box-shadow: none;
        &:focus,
        &:active {
          border-color: #ccc;
        }
      }
      button {
        border-radius: 0 5px 5px 0;
      }
    }
    .search-bar {
      input {
        width: 15vw;
        @include transition(width 0.5s);
        outline: none;
        box-shadow:none;
        border-color: #ccc;
        &:focus,
        &:active {
          outline: none;
          width: 20vw;
          box-shadow: 0 0 2px #ccc;
          border-color: #ccc;
        }
      }
      &:focus input,
      &:active input {
        outline: none;
        width: 20vw;
        box-shadow: 0 0 2px #ccc;
        border-color: #ccc;
      }
    }
    @media (max-width: $m-md) {
      padding:0 24px;
    }
  }
 }


// DEFAULT ROWS _ with sticky header
.g-ly {
  display:grid;
  grid-template-columns: 72px 48px 1fr 1fr minmax(80px, auto) minmax(150px, auto) minmax(220px, auto) minmax(220px, auto);
  font-size: 14px;
}

.g-container {
  overflow-y: auto;
  line-height:normal;
}
.g-header {
  position: sticky;
  top: 0;
  font-size:12px;
  color: $gray;
  background:$pearl;
  grid-template-rows: minmax(28px, auto);
  @extend .g-ly;
  @media (max-width: $m-md) {display:none;}

  > div {
    line-height: 24px;
    border-top:2px solid transparent;
    border-bottom:2px solid transparent;
    @include no-select; // remove browser selected background.
  }
  > div.sorted {
    border-bottom-color: $primary;
    &::after {
      content:"";
      display:inline-block;
      vertical-align: middle;
      margin-left:6px;
      @include square(11px);
      @include transition(transform 0.3s ease);
      background: url("../../img/icons/i_caret_line.svg") no-repeat center;
    }
  }
  .sorted.sort-asc::after {
    @include transform(rotate(0deg));
  }
  .sorted.sort-desc::after {
    @include transform(rotate(-180deg));
  }
}
.grid-table-row {
  @extend .g-ly;
  border-bottom:1px solid darken($pearl,10%);
  > div[data-sort] {
    cursor: pointer;
    &:hover {
      color:$black;
    }
  }
  > div {
    min-height: 17px;
    align-self: center;
    padding-left:0.5rem;
    padding-right:0.5rem;
    &:first-child {
      text-align: right;
    }
    .editable {
      border:none;
      border-left: 1px solid transparent;
      border-bottom:1px solid transparent;
      width: 100%;
      padding-top: 7px;
      padding-bottom: 7px;
      &.btn-group {
        padding-top: 5px;
        padding-bottom: 5px;
        background: none;
        border-left: none;
        border-right: none;
        > button {
          padding: 0;
          text-align: left;
          border: none;
          &:focus,
          &:active {
            box-shadow: none;
          }
        }
      }
    }
  }
  &:hover {
    .editable {
      border-bottom-color:$silver;
      background: url("../../img/icons/i_edit_input.svg") right center no-repeat;
      background-size: 24px;
    }
  }
  em {color:#999;}
  .icon-clear {
    color:$gray;
    opacity:0;
    @include transition(opacity 0.35s ease);
    &:hover {
      color:$red;
    }
  }

  pre {
    height: 200px;
    padding-left: 1px;
  }

  textarea.form-control, textarea.readonly-json {
    width: 100%;
    height: 230px;
    border: 1px solid $silver;
    border-radius: 5px;
    line-height: normal;
    padding: 5px 1px;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 10.7188px;
    resize: none;
    outline: none;
  }

  textarea.readonly-json[readonly] {
    border-color: transparent;
    background: none;
    padding: 7px 1px;
  }

  input.form-control {
    font-size: 14px;
    padding: 0;
  }

  .form-control.error {
    border-color: $red;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
  }

  @media (max-width: $m-md) {
    grid-template-columns: 1fr 1fr 1fr !important;
    padding: 24px 24px 24px 50px;
    position: relative;
    &:nth-child(2) {
      padding-top: 10px;
    }
    > div {
      padding-bottom: 0.5rem;
      align-self: normal;
      &:before {
        content: attr(data-title);
        display: block;
        font-size: 12px;
        color: $silver;
      }
      &:first-child {
        // delete row button
        left: 10px;
        position:absolute;
        top:24px;
      }
    }
  }
  @media (max-width: $m-sm) {
    grid-template-columns: 1fr 1fr !important;
  }
}
