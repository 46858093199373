

/* GLOBAL FONTS DEF. */
@mixin t1 {
	font-size:   18px;
	line-height: normal;
	font-weight: 300;
}
@mixin t2 {
	font-size:   14px;
	line-height: 20px;
	font-weight: normal;
}
@mixin t3 {
	font-size:   12px;
	line-height: 20px;
	font-weight: normal;
}
@mixin t4 {
	font-size:   12px;
  line-height: 16px;
  font-weight: 500;
}
@mixin t5 {
	font-size:   0.75rem;
	line-height: 1rem;
	font-weight: normal;
}
@mixin t6 {
  font-size:   12px;
  font-weight: 500;
	text-transform: uppercase;
}
@mixin t7 {
	font-size:   20px;
	font-weight: normal;
	line-height: normal;
}
@mixin t8 {
  font-size: 14px;
	text-transform: capitalize;
}
@mixin t9 {
  font-size: 16px;
}
@mixin t10 {
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
}
@mixin t11 {
  font-size: 32px;
  font-weight: normal;
  line-height: normal;
}

/* GLOBAL  */
$body-bg: $white;
$body-color: $black;

// Typography
$font-family-sans-serif: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-base: 0.875rem; // equal to 14px
$line-height-base: 1.5;
$headings-font-weight: 400 !default;
$font-weight-bold: 500;

// Links
$link-color: $primary;
$brand-primary: $primary;

// Buttons
$btn-primary-bg: $primary;
$btn-primary-border: $primary;
$btn-padding-y: .5rem;
$btn-padding-x: 1.25rem;
$btn-line-height: normal;
$btn-font-weight: 500;
$btn-default-bg: transparent;
$input-btn-padding-y-sm: .25rem;
$input-btn-padding-x-sm: 1rem;
$caret-width: .29em;


$inp-default-color: #ccc;
$inp-focus-color: #666;

$circle-lrg: 80px;
$circle-sml: 60px;

$c-nav-active : #2B2B2B;

$c-label : #5d6577;

$input-color: $black;
$input-color-placeholder: #aaa;
$input-placeholder-color: #aaa; // bt4
$input-focus-bg:transparent;

// DATE PICKER
$datepicker__font-size: 12px;
$datepicker__font-family: inherit;
$datepicker__background-color: transparent;
$datepicker__border-color: transparent;
$datepicker__highlighted-color: #3dcc4a;
$datepicker__muted-color: #ccc;
$datepicker__selected-color: $alice;
$datepicker__text-color: $black;
$datepicker__header-color: $black;

$datepicker__border-radius: 4px;
$datepicker__day-margin: 0.5px;
$datepicker__item-size: 24px;
$datepicker__margin: 11px;
$datepicker__navigation-size: .45rem;
$datepicker__triangle-size: 8px;

// REACT-SELECT
// control options
$select-input-bg:                   transparent !default;
$select-input-bg-disabled:          transparent !default;
$select-input-bg-focus:             $select-input-bg !default;
$select-input-border-color:         #ccc !default;
$select-input-border-radius:        0 !default;
$select-input-border-focus:         #666 !default; // dark gray
$select-input-box-shadow-focus:     none !default;
$select-input-border-width:         1px !default;
$select-input-height:               36px !default;
$select-input-internal-height:      ($select-input-height - ($select-input-border-width * 2)) !default;
$select-input-placeholder:          #aaa !default;
$select-text-color:                 #333 !default;
$select-link-hover-color:           $select-input-border-focus !default;
$select-input-hover-box-shadow:     none !default;

$select-padding-vertical:           8px !default;
$select-padding-horizontal:         10px ;

// menu options
$select-menu-zindex:                1000 !default;
$select-menu-box-shadow:            $select-input-hover-box-shadow !default;

$select-option-color:               lighten($select-text-color, 20%) !default;
$select-option-bg:                  $white !default;
$select-option-focused-color:       $select-text-color !default;
$select-option-focused-bg:          darken($pearl, 2%) !default;
$select-option-selected-color:      $select-text-color !default;
$select-option-selected-bg:         darken($pearl, 2%) !default;
$select-option-disabled-color:      lighten($select-text-color, 60%) !default;

$select-noresults-color:            lighten($select-text-color, 40%) !default;

// clear "x" button
$select-clear-size:                 floor(($select-input-height / 2)) !default;
$select-clear-color:                #999 !default;
$select-clear-hover-color:          $coral !default; // red
$select-clear-width:                ($select-input-internal-height / 2) !default;

// arrow indicator
$select-arrow-color:                $black !default;
$select-arrow-color-hover:          $black !default;
$select-arrow-width:                4px !default;

// loading indicator
$select-loading-size:               16px !default;
$select-loading-color:              $primary !default;
$select-loading-color-bg:           darken($silver, 5%) !default;

// multi-select item
$select-item-border-radius:         4px !default;
$select-item-gutter:                5px !default;
$select-item-padding-vertical:      2px !default;
$select-item-padding-horizontal:    5px !default;
$select-item-font-size:             inherit !default;
$select-item-color:                 #08c !default; // pale blue
$select-item-bg:                    #f2f9fc !default;
$select-item-border-color:          darken($select-item-bg, 10%) !default;
$select-item-hover-color:           darken($select-item-color, 5%) !default; // pale blue
$select-item-hover-bg:              darken($select-item-bg, 5%) !default;
$select-item-disabled-color:        #333 !default;
$select-item-disabled-bg:           #fcfcfc !default;
$select-item-disabled-border-color: darken($select-item-disabled-bg, 10%) !default;









// |----------------------------------------------------------------|
// |                         $header_height                         |
// |----------------------------------------------------------------|
// |                       $sub_header_height                       |
// |----------------------|-----------------------------------------|
// |    $sidebar-width    |         $settings-header-height         |
// |----------------------|-----------------------------------------|

$header_height: 56px;
$sub_header_height : 64px;
$sidebar-width: 280px;
$search-bar-height: 36px;
$settings-header-height: 60px;


/* FORM EDITORS */


/* set background opacity with fallback */
@mixin alpha-attribute($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacity($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}

/* set box shadow */
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
  -webkit-box-shadow:$top $left $blur $color #{$inset};
  -moz-box-shadow:$top $left $blur $color #{$inset};
  box-shadow:$top $left $blur $color #{$inset};
}

/* clearfix */
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
/* ellipsis text ... */
@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-webkit-input-placeholder  {color: $color;}
  &:-ms-input-placeholder {color: $color;}
}

// Disable user-select on an element
@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Sizing shortcuts
@mixin size($width, $height) {
  width: $width;
  height: $height;
}
@mixin square($size) {
  @include size($size, $size);
}

/* GRID MODEL */
// --------------------------------------------------
@mixin display-grid {
  display: -ms-grid;
  display: grid;
}

@mixin display-inline-grid {
  display: -ms-inline-grid;
  display: inline-grid;
}

@mixin col-span-($n) {
  grid-column-end: unquote("span") $n;
}


@mixin grid-child ($col-start, $col-end, $row-start, $row-end) {
  -ms-grid-column: $col-start;
  -ms-grid-column-span: $col-end - $col-start;
  -ms-grid-row: $row-start;
  -ms-grid-row-span: $row-end - $row-start;
  grid-column: #{$col-start}/#{$col-end};
  grid-row: #{$row-start}/#{$row-end};
}



/* FLEX MODEL */
// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
// inline flex display
@mixin inline-flex {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
     -moz-box-flex: $values;
      -webkit-flex: $values;
  	  -ms-flex: $values;
  	      flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
     -moz-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
     -moz-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
     -moz-flex-flow: $flow;
      -ms-flex-flow: $flow;
          flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
     -moz-box-ordinal-group: $val;
  	     -ms-flex-order: $val;
  	      -webkit-order: $val;
  		      order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
     -moz-flex-grow: $grow;
      -ms-flex-grow: $grow;
          flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
     -moz-flex-shrink: $shrink;
      -ms-flex-shrink: $shrink;
          flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
     -moz-flex-basis: $width;
      -ms-flex-basis: $width;
          flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
     -moz-align-content: $align;
      -ms-align-content: $align;
          align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
     -moz-align-items: $align;
      -ms-align-items: $align;
          align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
     -moz-align-self: $align;
      -ms-align-self: $align;
          align-self: $align;
}




/* TRANSITION */
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

/* TRANSLATE 3D */
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
     -moz-transform: translate3d($x, $y, $z);
       -o-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}

/* TRANSFORM */
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
// scale
@mixin scale($scale) {
	 @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
     -moz-transform-origin: $origin;
	     -o-transform-origin: $origin;
	    -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
          transform-origin: $origin;
}


// CSS Gradient Text
@mixin gradient-text($gradient, $bg : 'light') {
  @supports(mix-blend-mode: lighten) {
    display: inline-block;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;

      background: unquote($gradient);
      pointer-events: none;
    }

    @if ($bg == 'light') {
      color: #000;
      background: #fff;
      mix-blend-mode: multiply;

      &::before {
        mix-blend-mode: screen;
      }
    } @else {
      color: #fff;
      background: #000;
      mix-blend-mode: lighten;

      &::before {
        mix-blend-mode: multiply;
      }
    }
  }
}
