.home-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  .header {
    display: flex;
    margin-top: 0.5rem;
    justify-content: space-between;
    align-items: flex-end;
    h2 {
      font-size: 1.5rem;
      font-weight: bold;
    }
    select {
      font-size: 1.5rem;
    }
  }

  .general-insights {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    .panel {
      width: 25%;
      margin-right: 1.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .latest-logs,
  .most-frequent-logs {
    margin-top: 1.5rem;
  }

  .rules-insights {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    .panel {
      width: 50%;
      margin-right: 1.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
