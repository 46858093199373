@import '../../../assets/styles/_variables.scss';

.criteria-editor {
  .show-raw {
    text-align: right;
    a {
      color: $primary;
      cursor: pointer;
    }
  }

  .simple-criteria,
  .criteria-group,
  .criteria-buttons {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  .icon-delete {
    cursor: pointer;
    margin-left: 10px;
  }

  .left-operand {
    .select {
      width: 250px;
    }
  }

  .operator {
    margin: 0 10px;
    .select {
      width: 200px;
    }
  }
}
