@import '../../../assets/styles/_variables.scss';

.drawer {
  width: $cr-sidebar-width;
  flex-shrink: 0;
  box-shadow: rgba(0, 0, 0, 0.06) 0 5px 5px -3px, rgba(0, 0, 0, 0.043) 0 8px 10px 1px, rgba(0, 0, 0, 0.035) 0 3px 14px 2px;


  .drawer-container {
    width: $cr-sidebar-width;
    background-color: #232944;
    .drawer-toolbar {
      padding: 1rem;
      img {
        width: 120px;
      }
    }
    .drawer-content {
      overflow: auto;
      padding: 0 16px;

      hr {
        background: rgba(255, 255, 255, 0.1);
      }
      .list-item-group {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.7);
        .expand-collapse {
          svg {
            color: rgba(255, 255, 255, 0.5);
            font-size: 18px;
            transform: rotate(0deg);
            transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms;
          }
          &.opened {
            svg {
              transform: rotate(90deg);
            }
          }
        }

        .submenu {
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.3s cubic-bezier(0, 0, 0.2, 1);
          span {
            text-align: center;
          }
        }

        &.opened {
          .expand-collapse {
            svg {
              transform: rotate(90deg);
            }
          }

          .submenu {
            max-height: 1000px;
          }
        }
        .group {
          margin-top: 5px;
          text-transform: uppercase;
          font-size: 10px;
          text-align: center;
        }
        .account {
          font-size: 12px;
          margin-top: 15px;
          margin-bottom: 15px;
          text-transform: uppercase;
          padding-left:18px;
          padding-right: 18px;
          text-align: center;
          &.button {
            color: rgba(255, 255, 255, 0.7);
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          span {
            vertical-align: middle;
          }
          .expand-collapse {
            min-width: auto;
            vertical-align: middle;
          }
        }
      }

      .side-bar-link {
        span {
          font-size: 0.875rem;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: 400;
          line-height: 1.43;
          letter-spacing: 0.01071em;
        }
        color: #fff;
        > div {
          border-radius: 5px;
          margin: 10px 0;
          &:hover {
            background-color: rgba(255, 255, 255, 0.04);
          }
          > div {
            min-width: auto;
            margin-right: 10px;
            svg {
              font-size: 18px;
              color: #fff;
            }
          }
        }
        &.active-link {
          > div {
            background: #464d63;
          }
        }
        &.level-2 {
          svg {
            font-size: 5px;
            color: rgba(255, 255, 255, 0.7);
            margin-left: 6px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
