
.logs-table {
  padding: 20px 0;

  .message-cell {
    text-align: left;
    height: 50px;
    padding: 0 10px;
  }
}
