.cr-search-form {
  direction:ltr;
  position: relative;

  &__icon-search {
    position: absolute;
    left: 8px;
    cursor: pointer;
  }

  &__icon-clear {
    position: absolute;
    right: 8px;
    cursor: pointer;
  }

  &__input {
    /* stylelint-disable-next-line */
    padding-left: 35px !important;
  }
}

.no-results-message {
  color:#999;
  text-align:center;
  padding-top:50px;
}

/* HIGHLIGHTS SEARCH ITEMS */
.highlight {
  background:#ffc;
  padding:4px 0 2px;
  border-bottom: 1px dashed #333;
}
