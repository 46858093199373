// override bootstrap 4 variables
 $blue: #2196f3;
 $indigo: #536dfe;
 $purple: #9c27b0;
 $pink: #ff4081;
 $red: #f44336;
 $orange: #ff9800;
 $yellow: #ffeb3b;
 $green: #4caf50;
 $teal: #009688;
 $cyan: #00bcd4;
 $white: #fff;
$theme-colors: (
  "primary": #3891EB,
  "secondary": #fc5c7d,
  "success": #45b649,
  "danger": #f85032,
  "warning": #ffd700,
  "info": #00c9ff,
  "lightblue": #1976d2,
  "transparent": rgba(255,255,255,0)
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 260px;

// main navigation
$cr-main-navigation-docked-width: 60px;
$cr-main-navigation-opened-width: 300px;


$primary        : #783393;   // rgba(120,51,147,1);
$search-bar-height: 36px;
$white         : #fbfbfb;   // rgba(251,251,251,1);
$pearl         : #ebebeb;   // rgba(235,235,235,1);
$silver        : #c3c3c3;   // rgba(195,195,195,1);
$steel         : #999999;   // rgba(153,153,153,1);
$gray          : #777777;   // rgba(119,119,119,1);
$black         : #333333;   // rgba(51,51,51,1);
$input-color: $black;
$input-color-placeholder: #aaa;
$input-placeholder-color: #aaa; // bt4
$input-focus-bg:transparent;
$sidebar-width: 280px;

// WORM <-> COLD
// ------------------------------------------------------

// Start
$violet        : #783394;   // rgba(120,51,148,1);

// Deal
$rose          : #c14444;   // rgba(193,68,68,1);

// Deal accent
$cupid         : #fcac9f;   // rgba(252,172,159,1);

// Health
//$mandy         : #ef4581;   // rgba(239,69,129,1);
$mandy         : #f6709c;   // rgba(239,121,164,1);

// Health accent
$cotton        : #ffc7da;   // rgba(255,199,218,1);

// Lost
$coral         : #ff4148;   // rgba(255,65,72,1);

// Duration
$carrot        : #ff8f00;   // rgba(255,143,0,1);

// Limbo
$sun           : #ffb400;   // rgba(255,180,0,1);

// Duration accent
$lemon         : #ffd740;   // rgba(255,215,64,1);

// Frequency
$teal          : #26a69c;   // rgba(38,166,156,1);

// Frequency accent
$spring        : #00f3a8;   // rgba(0,243,168,1);

// Revenue
$salad         : #619e4c;   // rgba(97,158,76,1);

// Revenue accent
$grass         : #00e676;   // rgba(0,230,118,1);

// Won
$leaf          : #619e4d;   // rgba(97,158,77,1);

// Conversion
$ocean         : #086ba1;   // rgba(8,107,161,1);

// Conversion accent
$aqua          : #6cd2ff;   // rgba(108,210,255,1);

// Probability
$sky           : #4a90e2;   // rgba(74,144,226,1);

// Step
$alice         : #086ba2;   // rgba(8,107,162,1);

// Potential lost
$sweet         : #ff7466;   // rgba(255,116,102,1);

// Media Queries
// -------------------------
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 990px,
  xl: 1200px
);
$m-xs: map-get($grid-breakpoints,xs);
$m-sm: map-get($grid-breakpoints,sm);
$m-md: map-get($grid-breakpoints,md);
$m-lg: map-get($grid-breakpoints,lg);
$m-xl: map-get($grid-breakpoints,xl);
