@import "../variables";
@import "../mixins";

.users-page {
  padding: 2rem 0 2rem 0;
  .g-header,
  .grid-table-row {
    grid-template-columns: 48px 1fr 1fr minmax(80px, auto) minmax(150px, auto) minmax(220px, auto) minmax(220px, auto);
    @media (max-width: $m-md) {
      > div {
        &:nth-child(2) {
          // delete row button
          position:absolute;
          top:54px;
          left: 10px;
        }
        &:nth-child(3) {
          // delete row button
          position:absolute;
          top:80px;
          left: 10px;
        }
      }
    }
  }
}
