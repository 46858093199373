@import "variables";

$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome.scss';

// libraries
@import "../../../node_modules/bootstrap/scss/bootstrap";

// base
@import "base";
@import "animations";
@import "utils/utils";
