// show badge of current environment mode
// mode [ development > qa > staging ]


.environment {
  &--mode-development {
    background-color: #ff8f00;
  }
  &--mode-qa {
    background-color: #619e4d;
  }
  &--mode-staging {
    background-color: #d480e8;
  }
  &--mode-production {
    background-color: #d480e8;
  }

  &__wrapper {
    overflow:hidden;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:14px;
  }

  color: #fff;
  min-width: 72px; // same logo width
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  line-height:13px;
  position: fixed;
  top: -25px;
  left: 50%;
  display: block;
  float: left;
  padding: 0 10px;
  border-radius: 0 0 4px 4px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: top 500ms linear;
  transition: top 500ms linear;
  z-index: 999999;

  &::before  {
    content: "";
    position: inherit;
    top: 0;
    left: 0;
    right: 100%;
    height: 3px;
    background-color: inherit;
    -webkit-transition: left 1s ease 500ms;
    transition: left 1s ease 500ms;
  }
  &::after  {
    content: "";
    position: inherit;
    top: 0;
    left: 100%;
    right: 0;
    height: 3px;
    background-color: inherit;
    -webkit-transition: right 1s ease 500ms;
    transition: right 1s ease 500ms;
  }
  &--load {
    top:0;
    &::before {
      left: -50vw;
    }
    &::after {
      right: -50vw;
    }
  }
}
