@import '../../../assets/styles/_variables.scss';

.flow {
  display: flex;
  padding: 10px;
  align-items: center;

  .rule-attributes {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 15px;
    margin-right: 30px;
    cursor: pointer;

    > div:last-child {
      margin-left: auto;
      padding-left: 20px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
  }

  &.disabled {
    opacity: 0.3;
  }
}

.rule-edit-form {
  //width: 700px;
  .MuiDialog-paperWidthSm {
    max-width: 1000px;
    min-width: 800px;
  }

  .rule-edit-form-cnt {
    max-height: 500px;
    overflow: auto;
  }

  .rule-fields-cnt {
    width: 100%;

    > div {
      padding-top: 5px;
      margin-bottom: 20px;
    }

    .name-row {
      //display: flex;

      > div:first-child {
        display: flex;
        flex-grow: 1;

        > div:first-child {
          margin-right: 60px;
        }
      }

      .error {
        color: red;
      }
    }

    .options-row {
      display: flex;

      > label {
        margin-right: auto;
      }

    }

    .edit-criteria-link {
      a {
        color: $primary;
        cursor: pointer;
      }
    }
  }
}

