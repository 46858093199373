.page-spinner {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;
  background: rgba(255,255,255,0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    height: 2.5rem;
    color: #232944;
  }
  > div {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #232944;
  }
}
