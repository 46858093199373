.panel {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-align: center;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.06), 0 3px 4px 0 rgba(0, 0, 0, 0.042), 0 1px 8px 0 rgba(0, 0, 0, 0.036);
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    text-transform: uppercase;
    text-align: left;
    padding-left: 0.5rem;
    font-weight: 500;
    font-size: 0.87rem;
    color: rgba(52, 49, 76, 0.54);
  }
  .panel-container {
    font-size: 0.8rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    > h3 {
      margin: 0;
      font-weight: bold;
      font-size: 32px;
      line-height: 32px;
    }
    > svg {
      align-self: center;
      color: rgba(52, 49, 76, 0.54);
      margin-right: 3rem;
      position: absolute;
      left: 30px;
    }
  }

  > p {
    font-size: 0.9rem;
    text-align: center;
    color: rgba(52, 49, 76, 0.54);
    margin: 0;
  }
}
