@import './assets/styles/_variables.scss';

.app {
  .main {
    display: flex;
    .content {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      background: #fafafa;
      flex-grow: 1;
    }
  }

  a:link, a:visited, a:hover, a:active {
    text-decoration: none;
  }

  .field-label {
    color: #999;
    font-style: italic;
    font-size: 0.9rem;
  }


}
